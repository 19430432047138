<template>
  <div class="clientsList">

    <div class="card">
      <div class="card-header d-flex justify-content-between align-items-center">
        <div class="col-lg-3">
          <label for="">Фильтрация по клиенту</label>
          <a-select
              mode="multiple"
              placeholder="Выберите клиента"
              v-model="dataFilter.clients"
              style="width: 230px"
              optionLabelProp="label"
              optionFilterProp="label"
              @change="applyFilter"
            >
            <a-select-option v-for="client in clients" :key="client.id" :value="client.id" :label="client.name">
              {{client.name}}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-lg-3">
          <label for="">Фильтрация по сотруднику</label>
          <a-select
              mode="multiple"
              placeholder="Выберите сотрудника"
              v-model="dataFilter.employees"
              style="width: 230px"
              optionLabelProp="label"
              optionFilterProp="label"
              @change="applyFilter"
            >
            <a-select-option v-for="emploee in employees" :key="emploee.id" :value="emploee.id" :label="emploee.name">
              {{emploee.name}}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-lg-3">
          <label for="">Фильтрация по создателю</label>
          <a-select
              mode="multiple"
              placeholder="Выберите сотрудника"
              v-model="dataFilter.creators"
              style="width: 230px"
              optionLabelProp="label"
              optionFilterProp="label"
              @change="applyFilter"
            >
            <a-select-option v-for="employee in employees" :key="employee.id" :value="employee.id" :label="employee.name">
              {{employee.name}}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-lg-3">
          <label for="">Поиск по всему</label>
          <template>
            <a-input placeholder="Запрос"
            v-model="dataFilter.search"
            @change="applyFilter"
            />
          </template>
        </div>

      </div>
    </div>

    <mini-loader v-if="load" />
    <div class="card" style="overflow-x: auto; max-width: 100%">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5 class="text-dark">Все договора</h5>
        <a @click="createItem">
          <span class=" p-1">
            <button type="button" class="btn btn-success" >Добавить
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 20 20">
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
              </svg>
            </button>

          </span>
        </a>
      </div>
      <table class="table table-striped" id="addressesList">
        <thead>
          <tr>
            <th scope="col">#</th>
            <td>Клиент</td>
            <td>Имя клиента</td>
            <td>Номер договора</td>
            <td>Дата договора</td>
            <td>Телефон</td>
            <td>МФО</td>
            <td>Банк</td>
            <td>ИНН</td>
            <th scope="col">Действие</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, index) in data">
            <th scope="row">{{index+1}}</th>
            <td> <span v-if="value.client" style="color: #5355D3; cursor: pointer">{{value.client.name}}</span> <span v-else></span> </td>
            <td> {{value.client_name}} </td>
            <td> {{value.contract_number}} </td>
            <td> {{value.registration_date}} </td>
            <td> {{value.phone}} </td>
            <td> {{value.bank_code}} </td>
            <td> {{value.bank}} </td>
            <td> {{value.tin}} </td>
            <td style="display: flex">
              <!-- <a v-if="value.pdf">
                <span class="border rounded border-primary m-1 p-2 text-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                    <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"/>
                  </svg>
                </span>
              </a>
              <a @click="exportToWord(value)">
                <span class="border rounded border-primary m-1 p-2 text-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-word-fill" viewBox="0 0 16 16">
                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.485 6.879l1.036 4.144.997-3.655a.5.5 0 0 1 .964 0l.997 3.655 1.036-4.144a.5.5 0 0 1 .97.242l-1.5 6a.5.5 0 0 1-.967.01L8 9.402l-1.018 3.73a.5.5 0 0 1-.967-.01l-1.5-6a.5.5 0 1 1 .97-.242z"/>
                  </svg>
                </span>
              </a> -->
              <a  @click="showItem(value)">
                <span class="border rounded border-primary m-1 p-2 text-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                  </svg>
                </span>
              </a>
              <a @click="editItem(value)">
                <span class="border rounded border-success m-1 p-2 text-success">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                  </svg>
                </span>
              </a>
              <a @click="deleteItem(index, value)">
                <span class="border rounded border-danger m-1 p-2 text-danger">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                  </svg>
                </span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="m-10 text-dark text-center" style="border: 1px solid #DFE1E4; padding: 10px">1 - {{data.length}} данных  из {{totalResults}}</p>
    </div>

    <!-- Show modal -->
    <a-modal v-model="visibleShowModal" footer="">
      <div class="">
        <table class="table table-striped">
          <tbody>
            <tr>
              <td>Клиент: </td>
              <td><span v-if="item.client" style="color: #5355D3; cursor: pointer">{{item.client.name}}</span> <span v-else></span></td>
            </tr>
            <tr>
              <td>ФИО клиента</td>
              <td>{{item.client_name}}</td>
            </tr>
            <tr>
              <td>Дата договора</td>
              <td>{{item.registration_date}}</td>
            </tr>

            <tr>
              <td>Номер договора</td>
              <td>{{item.contract_number}}</td>
            </tr>

            <tr>
              <td>Адрес клиента</td>
              <td>{{item.address}}</td>
            </tr>

            <tr>
              <td>Телефон</td>
              <td>{{item.phone}}</td>
            </tr>

            <tr>
              <td>МФО</td>
              <td>{{item.bank_code}}</td>
            </tr>

            <tr>
              <td>Банк</td>
              <td>{{item.bank}}</td>
            </tr>

            <tr>
              <td>Расчетный счет</td>
              <td>{{item.checking_account}}</td>
            </tr>

            <tr>
              <td>Дата договора</td>
              <td>{{item.registration_date}}</td>
            </tr>

            <tr>
              <td>ИНН клиента</td>
              <td>{{item.tin}}</td>
            </tr>

            <tr>
              <td>ОКЕД</td>
              <td>{{item.oked}}</td>
            </tr>

            <tr>
              <td>Код НДС</td>
              <td>{{item.vat_code}}</td>
            </tr>

            <tr>
              <td>ФИО директора</td>
              <td>{{item.customer_director}}</td>
            </tr>

            <tr>
              <td>Должность руководителя клиента</td>
              <td>{{item.customer_director_position}}</td>
            </tr>

            <tr>
              <td>ФИО склонение (Клиент)</td>
              <td>{{item.customer_director_add}}</td>
            </tr>

            <tr>
              <td>Сотрудник</td>
              <td><span v-if="item.employee" style="color: #5355D3; cursor: pointer">{{item.employee.name}}</span> <span v-else></span></td>
            </tr>
            <tr>
              <td>Создатель договора</td>
              <td><span v-if="item.creator" style="color: #5355D3; cursor: pointer">{{item.creator.name}}</span> <span v-else></span></td>
            </tr>
            <tr>
              <td>Документы</td>
              <td>
                <div v-for="template in templates">
                  <div v-if="me.roles[0].name == 'operation' && template.visible == 'for_all'">
                    <span class="btn btn-info" @click="exportToWord(template, item)">
                      {{template.title}}
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                      </svg>
                    </span>
                    <hr style="margin: 5px">
                  </div>
                  <div v-else-if="me.roles[0].name == 'admin'">
                    <span class="btn btn-info" @click="exportToWord(template, item)">
                      {{template.title}}
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                      </svg>
                    </span>
                    <hr style="margin: 5px">
                  </div>
                </div>

                <span v-for="(file, index) in item.files">
                  <a :href="$store.state.urlBackend+file.file" class="btn btn-primary" style="margin: 3px">
                    {{'Файл №' + ++index}}
                  </a>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </a-modal>

    <!-- Create modal -->
    <a-modal v-model="visibleCreateModal" title="Добавить договора"  footer="" class="address-create">
      <div class="mb-3">
        <label for="">Клиент <span style="color: #e55353">*</span></label>
        <a-select
            placeholder="Клиент"
            style="width: 100%"
            v-model="newItem.client_id"
            optionLabelProp="label"
            optionFilterProp="label"
            :allowClear="true"
            :class="{'is-invalid': validateErrors.client_id}"
          >
          <a-select-option v-for="client in clients" :key="client.id" :value="client.id" :label="client.name">
            {{client.name}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.client_id"> {{value}} </div>
      </div>
      <div class="mb-3">
        <label for="">Номер договора <span style="color: #e55353">*</span></label>
        <template>
          <a-input placeholder="Номер договора" v-model="newItem.contract_number"  class="form-control" :class="{'is-invalid': validateErrors.contract_number}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.contract_number"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Дата договора <span style="color: #e55353">*</span></label>
        <template>
          <a-date-picker :defaultValue="item.registration_date"  :class="{'is-invalid': validateErrors.registration_date}" style="width: 100%" @change="changeRegistrationDate" placeholder="Выберите дату и время" show-time format="DD-MM-YYYY"></a-date-picker>
          <div class="invalid-feedback" v-for="value in validateErrors.registration_date"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Имя клиента <span style="color: #e55353">*</span></label>
        <template>
          <a-input v-model="newItem.client_name" placeholder="Имя клиента"  class="form-control" :class="{'is-invalid': validateErrors.client_name}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.client_name"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Адрес клиента</label>
        <template>
          <a-input placeholder="Адрес клиента" v-model="newItem.address" class="form-control" :class="{'is-invalid': validateErrors.address}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.address"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Телефонный номер <span style="color: #e55353">*</span></label>
        <template>
          <a-input placeholder="Телефонный номер" v-model="newItem.phone"  class="form-control" :class="{'is-invalid': validateErrors.phone}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.phone"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">МФО (Пятизначный код банка)</label>
        <template>
          <a-input placeholder="МФО (Пятизначный код банка)" v-model="newItem.bank_code"  class="form-control" :class="{'is-invalid': validateErrors.bank_code}"
          @change="searchBankCode"/>
          <div class="invalid-feedback" v-for="value in validateErrors.bank_code"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Банк (филиал банка)</label>
        <template>
          <a-input placeholder="Банк (филиал банка)" v-model="newItem.bank"  class="form-control" :class="{'is-invalid': validateErrors.bank}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.bank"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Расчетный счет клиента</label>
        <template>
          <a-input placeholder="Расчетный счет клиента" v-model="newItem.checking_account"  class="form-control" :class="{'is-invalid': validateErrors.checking_account}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.checking_account"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">ИНН клиента</label>
        <template>
          <a-input placeholder="ИНН клиента" v-model="newItem.tin"  class="form-control" :class="{'is-invalid': validateErrors.tin}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.tin"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">ОКЭД</label>
        <template>
          <a-input placeholder="ОКЭД" v-model="newItem.oked"  class="form-control" :class="{'is-invalid': validateErrors.oked}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.oked"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Код НДС</label>
        <template>
          <a-input placeholder="Код НДС" v-model="newItem.vat_code"  class="form-control" :class="{'is-invalid': validateErrors.vat_code}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.vat_code"> {{value}} </div>
        </template>
      </div>

      <div class="mb-3">
        <label for="">ФИО директора</label>
        <template>
          <a-input placeholder="ФИО директора" v-model="newItem.customer_director"  class="form-control" :class="{'is-invalid': validateErrors.customer_director}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.customer_director"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Должность руководителя клиента</label>
        <template>
          <a-input placeholder="Должность руководителя клиента" v-model="newItem.customer_director_position"  class="form-control" :class="{'is-invalid': validateErrors.customer_director_position}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.customer_director_position"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">ФИО склонение (Клиент)</label>
        <template>
          <a-input placeholder="ФИО склонение (Клиент)" v-model="newItem.customer_director_add"  class="form-control" :class="{'is-invalid': validateErrors.customer_director_add}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.customer_director_add"> {{value}} </div>
        </template>
      </div>

      <div class="mb-3">
        <label for="">Сотрудник <span style="color: #e55353">*</span></label>
        <a-select
            placeholder="Сотрудник"
            style="width: 100%"
            v-model="newItem.employee_id"
            optionLabelProp="label"
            optionFilterProp="label"
            :default-value="me.id"
            :allowClear="true"
            :class="{'is-invalid': validateErrors.employee_id}"
          >
          <a-select-option v-for="client in employees" :key="client.id" :value="client.id" :label="client.name">
            {{client.name}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.employee_id"> {{value}} </div>
      </div>
      <div class="d-flex mt-3">
        <button type="submit" class="ml-auto btn btn-primary" @click="storeItem">Добавить</button>
      </div>
    </a-modal>

    <!-- Edit modal -->
    <a-modal v-model="visibleEditModal" title="Изменить договора"  footer="">
      <div class="mb-3">
        <label for="">Клиент <span style="color: #e55353">*</span></label>
        <a-select
            placeholder="Клиент"
            style="width: 100%"
            v-model="item.client_id"
            optionLabelProp="label"
            optionFilterProp="label"
            :allowClear="true"
            :class="{'is-invalid': validateErrors.client_id}"
          >
          <a-select-option v-for="client in clients" :key="client.id" :value="client.id" :label="client.name">
            {{client.name}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.client_id"> {{value}} </div>
      </div>
      <div class="mb-3">
        <label for="">Номер договора <span style="color: #e55353">*</span></label>
        <template>
          <a-input placeholder="Номер договора" v-model="item.contract_number"  class="form-control" :class="{'is-invalid': validateErrors.contract_number}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.contract_number"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Дата договора <span style="color: #e55353">*</span></label>
        <template>
          <a-date-picker :defaultValue="item.registration_date"  :class="{'is-invalid': validateErrors.registration_date}" style="width: 100%" @change="changeRegistrationDateEditForm" placeholder="Выберите дату и время" show-time format="DD-MM-YYYY"></a-date-picker>
          <div class="invalid-feedback" v-for="value in validateErrors.registration_date"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Имя клиента <span style="color: #e55353">*</span></label>
        <template>
          <a-input v-model="item.client_name" placeholder="Имя клиента"  class="form-control" :class="{'is-invalid': validateErrors.client_name}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.client_name"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Адрес клиента</label>
        <template>
          <a-input placeholder="Адрес клиента" v-model="item.address" class="form-control" :class="{'is-invalid': validateErrors.address}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.address"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Телефонный номер <span style="color: #e55353">*</span></label>
        <template>
          <a-input placeholder="Телефонный номер" v-model="item.phone"  class="form-control" :class="{'is-invalid': validateErrors.phone}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.phone"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">МФО (Пятизначный код банка)</label>
        <template>
          <a-input placeholder="МФО (Пятизначный код банка)" v-model="item.bank_code"  class="form-control" :class="{'is-invalid': validateErrors.bank_code}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.bank_code"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Банк (филиал банка)</label>
        <template>
          <a-input placeholder="Банк (филиал банка)" v-model="item.bank"  class="form-control" :class="{'is-invalid': validateErrors.bank}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.bank"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Расчетный счет клиента</label>
        <template>
          <a-input placeholder="Расчетный счет клиента" v-model="item.checking_account"  class="form-control" :class="{'is-invalid': validateErrors.checking_account}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.checking_account"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">ИНН клиента</label>
        <template>
          <a-input placeholder="ИНН клиента" v-model="item.tin"  class="form-control" :class="{'is-invalid': validateErrors.tin}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.tin"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">ОКЭД</label>
        <template>
          <a-input placeholder="ОКЭД" v-model="item.oked"  class="form-control" :class="{'is-invalid': validateErrors.oked}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.oked"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Код НДС</label>
        <template>
          <a-input placeholder="Код НДС" v-model="item.vat_code"  class="form-control" :class="{'is-invalid': validateErrors.vat_code}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.vat_code"> {{value}} </div>
        </template>
      </div>

      <div class="mb-3">
        <label for="">ФИО директора</label>
        <template>
          <a-input placeholder="ФИО директора" v-model="item.customer_director"  class="form-control" :class="{'is-invalid': validateErrors.customer_director}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.customer_director"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Должность руководителя клиента</label>
        <template>
          <a-input placeholder="Должность руководителя клиента" v-model="item.customer_director_position"  class="form-control" :class="{'is-invalid': validateErrors.customer_director_position}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.customer_director_position"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">ФИО склонение (Клиент)</label>
        <template>
          <a-input placeholder="ФИО склонение (Клиент)" v-model="item.customer_director_add"  class="form-control" :class="{'is-invalid': validateErrors.customer_director_add}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.customer_director_add"> {{value}} </div>
        </template>
      </div>

      <div class="mb-3">
        <label for="">Сотрудник <span style="color: #e55353">*</span></label>
        <a-select
            placeholder="Сотрудник"
            style="width: 100%"
            v-model="item.employee_id"
            optionLabelProp="label"
            optionFilterProp="label"
            :default-value="me.id"
            :allowClear="true"
            :class="{'is-invalid': validateErrors.employee_id}"
          >
          <a-select-option v-for="client in employees" :key="client.id" :value="client.id" :label="client.name">
            {{client.name}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.employee_id"> {{value}} </div>
      </div>

      <div v-for="(file, index) in item.files">
        <a :href="$store.state.urlBackend+file.file" class="btn btn-primary" style="margin: 3px">
          {{'Файл №' + (index+1)}}
        </a>
        <span @click="deleteFile(index, file)" class="btn btn-danger"> Удалить </span>
      </div>

      <template>
        <a-upload-dragger
          :action="$store.state.urlBackend + '/api/clients/contracts/file-upload/' + item.id"
          :headers='{"Authorization": `Bearer ` + access_token}'
          name="file"
          :multiple="true"
          @change="handleChange"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
          <p class="ant-upload-text">
            Перетащите файл сюда
          </p>
          <p class="ant-upload-hint">
            <div>Поддерживаемые расширения: docx, doc</div>
            <div>Максимальный размер файла: 8МБ</div>
          </p>
        </a-upload-dragger>
      </template>
      <div class="d-flex mt-3">
        <button type="submit" class="ml-auto btn btn-primary" @click="updateItem">Сохранить</button>
      </div>
    </a-modal>

    <!-- Delete modal -->
    <a-modal v-model="visibleDeleteModal" title="Подтверждение на удаление" ok-text="Удалить" cancel-text="Отменить" @ok="destroyItem">
      <h6 style="color: red">Точно хотите безвозвратно удалить ?</h6>
    </a-modal>

  </div>
</template>

<script>
import axios from 'axios'
import moment, { Moment } from 'moment';
import { defineComponent, ref } from 'vue';
const data = '';
const dateFormat = 'DD-MM-YYYY';
import banks from '../../../public/bankbase.json';

export default {
  name: "Contracts",
  data() {
    return {
      index: null,
      data,
      banks,
      item: [],
      file: '',
      access_token: localStorage.token,
      dragging: false,
      newItem: {
        contract_number: null,
        registration_date: ref<Moment>(moment('20-02-2022', dateFormat)),
        client_name: '',
        address: '',
        phone: '',
        bank_code: '',
        bank: '',
        checking_account: '',
        tin: '',
        oked: '',
        vat_code: '',
        client_id: '',
        employee_id: null,

        customer_director: '',
        customer_director_position: '',
        customer_director_add: '',
      },
      oldItem: {
        contract_number: null,
        registration_date: ref<Moment>(moment('20-02-2022', dateFormat)),
        client_name: '',
        address: '',
        phone: '',
        bank_code: '',
        bank: '',
        checking_account: '',
        tin: '',
        oked: '',
        vat_code: '',
        client_id: '',
        employee_id: null,

        customer_director: '',
        customer_director_position: '',
        customer_director_add: '',
      },
      dataFilter: {
        clients: [],
        employees: [],
        creators: [],
        search: '',
        page: 1
      },
      validateErrors: [],
      templates: [],

      countries: [{id: 192, title: 'Узбекистан'}],
      regions: [],
      cities: [],
      typeOfAddress: [{title: "Жилое", value: 'residential'},{title: "Коммерческое", value: 'commercial'}],
      clients: [],
      employees: [],

      index: '',
      currentPage: 1,
      totalResults: 0,
      scrolled: false,
      old_height: 0,
      last_page: 1,

      load: true,
      visibleShowModal: false,
      visibleEditModal: false,
      visibleCreateModal: false,
      visibleDeleteModal: false,

    }
  },
  mounted(){

    console.log(this.banks);
    this.getAllContracts();
    this.getAllTemplates();

    this.getClients();
    this.getEmployees();

    window.onscroll = () => {
      let height = document.getElementById('addressesList').clientHeight
      if ((window.innerHeight + document.documentElement.scrollTop) >= height) {
        if(this.old_height!=height){
          this.old_height = height;
          let limit = this.currentPage + 1;
          if(limit <= this.last_page){
            this.dataFilter.page = limit;
            this.addContracts();
          }
        }
      }
    };

    this.newItem.employee_id = this.me.id;
  },
  methods: {
    searchBankCode(){
      console.log(this.newItem.bank_code);
      let thisBank = this.banks.find((el) => el.cod === this.newItem.bank_code);

      if(thisBank){
        console.log(thisBank);
        this.newItem.bank = thisBank.bank;
      }
    },

    deleteFile(index, file){
      this.load = true;
      axios.delete('/clients/contracts/file-delete/'+file.id).then((response) => {
        if(response.status == 200){
          this.$message.success(
            response.data.success,
            3
          );
          this.item.files.splice(index, 1)
        }
      }).catch((error)  =>  {
        if(error.response.status == 404){
          this.$message.error(
            error.response.data.error,
            3
          );
        }
      }).finally(() => {this.load = false;});
    },
    handleChange(info) {
      const status = info.file.status;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        this.$message.success(`${info.file.name} файл успешно загружен.`);
      } else if (status === 'error') {
        this.$message.error(`${info.file.name} не корректный файл.`);
      }
    },
    getAllTemplates(){
      axios.get('/clients/contracts/all/templates').then((response) => {
        if(response.status == 200){
          this.templates = response.data;
        }else if(response.status == 204){
          this.$message.error(
            "Шаблоны договоров не найдено",
            3
          )
        }
      }).catch((error) => {

      }).finally(()=>{
        this.load = false;
      });

    },
    addContracts(){
      this.load = true;
      axios.get('/clients/contracts/all', {params: this.dataFilter }).then((response) => {
        if(response.status == 200){
          this.data = this.data.concat(response.data.data);
          this.totalResults = response.data.total;
          this.last_page = response.data.last_page;
          this.currentPage = response.data.current_page;
        }else if(response.status == 204){
          this.$message.error(
            "Нет соответствующих договоров",
            3
          )
        }
      }).catch((error) => {

      }).finally(()=>{
        this.load = false;
      });
    },
    getAllContracts(){
      this.dataFilter.page = 1;
      this.old_height = 0;
      axios.get('/clients/contracts/all', {params: this.dataFilter }).then((response) => {
        if(response.status == 200){
          this.data = response.data.data;
          this.totalResults = response.data.total;
          this.last_page = response.data.last_page;
          this.currentPage = response.data.current_page;
        }else if(response.status == 204){
          this.data = [];
          this.totalResults = 0;
          this.last_page = 0;
          this.currentPage = 0;
          this.$message.error(
            "Нет соответствующих договоров",
            3
          )
        }
      }).catch((error) => {

      }).finally(()=>{
        this.load = false;
      });
    },

    createItem(){
      this.visibleCreateModal = true;
    },
    editItem(value){
      this.visibleEditModal = true;
      this.item = value;
      this.validateErrors = [];
      this.$forceUpdate();
    },
    showItem(value){
      this.visibleShowModal = true;
      this.item = value;
    },
    deleteItem(index, value){
      this.item = value;
      this.index = index;
      this.visibleDeleteModal = true;
    },

    storeItem(){
        this.load = true;
        axios.post('/clients/contracts', this.newItem).then((response) => {
          if(response.status == 201){
            this.data = this.data.concat(response.data);
            this.totalResults++;
            this.newItem = this.oldItem;
            this.validateErrors = [];
            this.visibleCreateModal = false;
            this.$message.success(
              "Успешно добавлено",
              3
            );

          }
        }).catch((error) => {
          if(error.response.status == 422){
            this.$message.error(
              error.response.data.message,
              3
            );
            this.validateErrors = error.response.data.errors
          }else if(error.response.status == 404){
            this.$message.error(
              error.response.data.error,
              3
            );
          }
        }).finally(() => {
          this.load = false;
        })
    },
    updateItem(){
      this.load = true;
      axios.put('/clients/contracts/'+this.item.id, this.item).then((response) => {
        if(response.status == 200){
          this.$message.success(
            "Успешно изменено",
            3
          );
          this.item = response.data;
          console.log(response.data);
          this.validateErrors = [];
          this.visibleEditModal = false;
        }
      }).catch((error) => {
        if(error.response.status == 422){
          this.$message.error(
            error.response.data.message,
            3
          );
          this.validateErrors = error.response.data.errors;
        }else if(error.response.status == 404){
          this.$message.error(
            error.response.data.error,
            3
          )
        }else if(error.response.status == 500){
          this.$message.error(
            "Ошибка сервера",
            3
          );
        }
      }).finally(() => {
        this.load = false;
      });
    },
    destroyItem(){
      this.load = true;
      axios.delete('/clients/contracts/'+this.item.id).then((response) => {
        if(response.status == 200){
          this.$message.success(
            response.data.success,
            3
          );
          this.data.splice(this.index, 1)
          this.totalResults--;
          this.visibleDeleteModal = false;
        }
      }).catch((error) => {
        if(error.response.status == 404){
          this.$message.error(
            error.response.data.error,
            3
          );
        }else if(error.response.status == 500){
          this.$message.error(
            "Ошибка сервера",
            3
          );
        }
      }).finally(() => {
        this.load = false;
      });
    },

    exportToWord(template, contract){
      this.load = true;
      // axios.post('/clients/contracts/export-to-word/'+value.id, {headers: {"Access-Control-Allow-Origin": "*"}}).then((response)  =>  {
      //   if(response.status == 200){
      //     this.$message.success(
      //       'Успешно экспортировано',
      //       3
      //     );
      //     console.log(response.data)
      //   }
      // }).catch((error)  =>  {
      //   console.log(error)
      // });

      axios.post('/clients/contracts/export-to-word/' + template.id + '/' + contract.id, {headers: {'Access-Control-Allow-Origin': '*','Content-Disposition': 'form-data'}}).then((response) => {

        if(response.status == 200){
          console.log(response.data);
          window.location.href = this.$store.state.urlBackend + response.data.url;
          // const blob = new Blob([response.data], { type:'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
          // const downloadURL = window.URL.createObjectURL(blob);
          // console.log(downloadURL);
          // const link = document.createElement('a');
          // link.href = downloadURL;
          // link.download = 'data.docx';
          // link.click();
        }
      }).catch((error) => {
        if(error.response.status == 404){
          this.$message.error(
            error.response.data.error
          )
        }
      }).finally(() => {this.load = false});
    },

    getClients(){
      axios.get('/employee/clients').then((response) => {
        if(response.status == 200){
          this.clients = response.data;
          if(this.me.roles[0].name == 'operation'){
            this.clients = this.clients.filter((e) => {
              return e.branchoffice_id === this.me.branchoffice_id;
            });
          }
        }else{
          this.$message.error(
            'Клиенты не найдено',
            3
          );
        }
      }).catch((error) => {
        if(error.response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }else{
          this.$message.error(
            'Клиенты не найдено',
            3
          );
        }
      }).finally(() => {
        this.load = false;
      });
    },
    getEmployees(){
      axios.get('/employee/get/users/managers').then((response) =>  {
        if(response.status == 200){
          this.employees = response.data;
          if(this.me.roles[0].name == 'operation'){
            this.employees = this.employees.filter((e) => {
              return e.branchoffice_id === this.me.branchoffice_id;
            });
          }
        }
      }).catch((error)  =>  {
        if(response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }
      }).finally(() =>  {});
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    changeRegistrationDate(date, dateString){
      this.newItem.registration_date = dateString;
      console.log(dateString);
    },
    changeRegistrationDateEditForm(date, dateString){
      this.item.registration_date = dateString;
      console.log(dateString);
    },

    applyFilter(){
      this.load = true;
      this.getAllContracts();
    },
    applyFilterAutocomplete(){
      if(this.itemName.length > 1 || this.itemName.length == 0){
        this.old_height = 0;
        this.load = true;
        axios.post('/branches/international/autocomplete/search/with/paginate', this.dataFilter, {params: {query: this.itemName}})
        .then(response => {
          if(response.status == 200){
            this.data = response.data.data;
            this.totalResults = response.data.total;
            this.load = false;
            this.last_page = response.data.last_page;
          }else if(response.status == 204){
            this.data = [];
            this.totalResults = 0;
            this.load = false;
            this.$message.error(
              'Офисов не найдено',
              3
            );
          }
        }).catch(error => {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
            this.load = false;
          }else if(error.response.status == 204){
            this.data = [];
            this.totalResults = 0;
            this.load = false;
            this.$message.error(
              'Офисов не найдено',
              3
            );
          }else{
            this.load = false
          }
        });
      }
    },
  },
  computed: {
    me () {
      return JSON.parse(localStorage.me)
    }
  },
  watch: {
    "visibleMap": function(value){
      if(value){
        setTimeout(() => {
          this.driving = "DRIVING";
        }, 1000);
      }else{
        this.driving = "WALKING";
      }
    },
  }
}
</script>

<style>
  .ant-modal-mask{
    z-index: 2000 !important;
  }
  .ant-modal-wrap{
    z-index: 2000 !important;
  }
  .ant-message{
    z-index: 2010 !important
  }
  .ant-message-notice{
    z-index: 2020 !important;
  }
  .ant-message-notice-content{
    z-index: 2020 !important;
  }
  .ant-select-dropdown{
    z-index: 2000 !important;
  }
  .address-create .ant-modal{
    width: 600px !important;
    height: 100%;
    top: 10px;
  }
  .map__modal .ant-modal{
    width: 1000px !important;
    height: 500px !important;
    top: 10px;
  }

  .my-img-circle{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid #e9e9e9;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .my-img-circle img{
    width: 75px;
  }




  .dropZone {
    width: 100%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px dotted #2e94c4;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #5C5C5C;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 100%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }
  .ant-calendar-picker-container{
    z-index: 2030 !important;
  }
  .removeFile {
    width: 200px;
  }
</style>
